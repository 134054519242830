import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class MainActionSheetMenuService {
  #showTabs = signal(false);

  public readonly show$ = this.#showTabs.asReadonly();

  public setShowsTabsValue(bool: boolean): void {
    this.#showTabs.set(bool);
  }
}
